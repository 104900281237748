import React, { useEffect, useState, useRef } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import { Button, Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import AddIcon from "@mui/icons-material/Add";
import { NavLink } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddComponent from "../Global/AddComponent";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "../../Images/CheckCircleFilled.png";
import { MenuItem } from "@mui/material";
import Icon2 from "../../Images/CancelFilled.png";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { CheckCircleOutline, Email, HighlightOff } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import "./contribute.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoneIcon from "@mui/icons-material/Done";
import Menu from "@mui/material/Menu";
import Swal from "sweetalert2";
import InfoIcon from "../../Images/InfoIcon.png";

const columns = [
  { id: 'no', label: 'Sl No' },
  { id: 'ComponentName', label: 'Component Name' },
  { id: 'ComponentDetails', label: 'Component Details' },
  { id: 'Category', label: 'Category' },
  { id: 'Technology', label: 'Technology' },
  { id: 'ComponentState', label: 'ComponentState' },
  { id: 'actions', label: 'Actions' }
];

export default function StickyHeadTable({ tabVal }) {
  const currentTab = 1;
  const { instance } = useMsal();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ComponentPopup, setComponentpopup] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 })
  const [isOpen, setIsOpen] = useState(false);
  const [itemVal, setItemVal] = useState([])
  const [openDropdownRow, setOpenDropdownRow] = useState(null);
  const [selectedComponentState, setSelectedComponentState] = useState("");
  const [filterByComponentState, setFilterByComponentState] = useState("");
  const [addtoPlaygroundSbuttonClick, setAddtoPlaygroundbuttonClick] = useState({
    addingState: false,
    selectedRowId: ""
  })
  const dropdownRef = useRef(null);
  const dialogRef = useRef(null);
  const fileInput1 = useRef(null);
  const fileInput2 = useRef(null);
  const [newformData, setnewFormData] = useState({
    PackageURL: "",
  });

  const [formData, setFormData] = useState({
    JsonFile: null,
    GitHubLink: "",
    OpenSrcLib: "",
    HostedURL: "",
    DesignDocs: "",
    HowToUseHURL: "",
    ChooseVertical: "",
    TypeOfComponent: "",
    UploadIcon: null,
    UploadBGIcon: null,
    checkbox: false,
  });
  const uniqueComponentStates = [...new Set(tableData.map(item => item.ComponentState))];
  const [isLoading, setIsLoading] = useState(true);
  const styli = {
    position: 'absolute',
    top: `${dropdownPosition.top + 15}px`,
    left: `${dropdownPosition.left - 10}px`
  }

  const playgroundAddStyli = {
    position: 'absolute',
    top: `${dropdownPosition.top + 15}px`,
    left: `${dropdownPosition.left - 116}px`
  }

  useEffect(() => {
    TableDataFetch();
  }, [filterByComponentState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownRow(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setPage(0); // Reset pagination to the first page when the filter changes
  }, [filterByComponentState]);

  const TableDataFetch = async () => {
    console.log("Filtering data is activated :",selectedComponentState)
    setIsLoading(true);
    try {
      const currentUserEmail = instance.getAllAccounts()?.[0]?.username;
      const response = await fetch("https://prod.appstudioltts.com/api/linkstateupdate/fetchdata");
      const data = await response.json();
      const myComponentsData = data.filter(item => item.Email === currentUserEmail);
      if (filterByComponentState!="" && filterByComponentState != undefined && filterByComponentState != null) {
        setFilteredTableData(myComponentsData.filter(item => item.ComponentState === filterByComponentState));
      } else {
        setFilteredTableData(myComponentsData);
        setTableData(myComponentsData);
      }
    setIsLoading(false);
    } catch (error) {
      console.log("Error occurred while fetching", error);
    setIsLoading(false);

    }
  }

  const AddedStateUpdate = async (selectedRowId) => {
    try {
      const formDataToSend = {
        ComponentState: "Adding to Playground",
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataToSend),
      };
      const response = await fetch(`https://prod.appstudioltts.com/api/linkstateupdate/updatedata/${selectedRowId}`, requestOptions);
    } catch (error) {
      console.log("Error occured while fetching", error);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0); // Reset to the first page when rows per page changes
  };

  const handleOpenFilterPopup = () => {
    setOpenFilterPopup(true);
  };

  const handleSubmitP = async () => {
    console.log("Handle submit is activated")
    if (!selectedRowData) {
      return;
    }
    const newformDataToSend = {
      PublishPackageUrl: newformData.PackageURL,
    };

    const requestOptionsP = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newformDataToSend),
    };

    try {
      console.log("Sending request with data:", newformDataToSend);

      const responseP = await fetch(
        `https://prod.appstudioltts.com/api/linkstateupdate/updatedata/${selectedRowData._id}`,
        requestOptionsP
      );

      console.log("Response status:", responseP.status);

      if (responseP.ok) {
        const responseDataP = await responseP.json();
        console.log("Response data:", responseDataP);

        setnewFormData({
          PackageURL: "",
        });

        setSelectedRowData(null);

        toast.success("Form submitted successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        throw new Error("Failed to submit the form", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit form", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    setOpenLinksDialog(false);
  }

  const isFormValidP = () => {
    return newformData.PackageURL !== "";
  };

  const handleOpenMyComponentsDialog = (rowData) => {
    setSelectedRowData(rowData);
    if (rowData.ComponentState === "In Progress") {
      setOpenMyComponentsDialog(true);
    } else if (rowData.ComponentState === "Ready to Publish") {
      setOpenLinksDialog(true);
    }
    setOpenDropdownRow(null)
  };
  const [openLinksDialog, setOpenLinksDialog] = useState(false);

  const handleRemoveRow = (rowId) => {
    const currentUserEmail = instance.getAllAccounts()?.[0]?.username

    // Fetch the data of the deleted row
    const deletedRow = filteredTableData.find((item) => item._id === rowId);
    // Send the deleted row data to the API
    fetch("https://prod.appstudioltts.com/api/request/community-request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ComponentName: deletedRow.ComponentName,
        ComponentDetails: deletedRow.ComponentDetails,
        Category: deletedRow.Category,
        Technology: deletedRow.Technology
        // FlagValue: deletedRow.FlagValue
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Deleted row data sent successfully:", data);
      })
      .catch((error) => {
        console.error("Error sending deleted row data:", error);
      });

    // Remove the row from the table
    fetch(
      `https://prod.appstudioltts.com/api/linkstateupdate/removedata/${rowId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(() => {
        const updatedTableData = tableData.filter((item) => item._id !== rowId);
        setTableData(updatedTableData);
      })
      .catch((error) => {
        console.error("Error removing row:", error);
      });

    fetch("https://prod.appstudioltts.com/api/linkstateupdate/fetchdata")
      .then((response) => {
        response.json()
      })
      .then((data) => {
        const myComponentsData = data.filter(
          (item) => item.Email === currentUserEmail
        );
        setTableData(myComponentsData);
        setFilteredTableData(myComponentsData);
      })
      .catch((error) => {
        console.error("Error fetching data for My Components: ", error);
      });

    setOpenDropdownRow(null)
  };
  const [openMyComponentsDialog, setOpenMyComponentsDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  // Movert Icon Open or Close
  const handleCloseDropdown = () => {
    setOpenDropdownRow(null);
    setIsOpen(!isOpen)
  };
  // -----------------------------------------

  const handleCloseMyComponentsDialog = () => {
    setOpenMyComponentsDialog(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: !!checked,
    });
  };

  const isURL = (input) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(input);
  };

  // ------ Working ------------------
  const [files, setFiles] = useState({
    JsonFile: null,
    UploadIcon: null,
    UploadBGIcon: null
  });
  const handleFileChange = (e) => {
    setFiles((prevFiles) => ({ ...prevFiles, [e.target.name]: e.target.files[0] }));
    setUploadSuccess(true);
  };
  // -----------------------------------------

  // ----------------------- Working After the  FormData ------------------------
  const handleSubmitLinks = async (e) => {
    e.preventDefault();
    if (!selectedRowData) {
      return;
    }

    const UpdateformData = new FormData();
    console.log("FormData is", formData, selectedRowData)
    UpdateformData.append('githubUrl', formData.GitHubLink);
    UpdateformData.append('hostedUrl', formData.HostedURL);
    UpdateformData.append('designDocuments', formData.DesignDocs);
    UpdateformData.append('documentsOnHostedUrl', formData.HowToUseHURL);
    UpdateformData.append('SonarQubeTestedUrl', formData.OpenSrcLib);
    UpdateformData.append('Email', instance.getAllAccounts()[0]?.username);
    UpdateformData.append('checkbox', formData.checkbox);
    UpdateformData.append('ChooseVertical', formData.ChooseVertical);
    UpdateformData.append('TypeOfComponent', formData.TypeOfComponent);
    UpdateformData.append('ComponentName', selectedRowData.ComponentName);
    UpdateformData.append('ComponentState', "Ready to Review");
    UpdateformData.append('ComponentDetails', selectedRowData.ComponentDetails);
    UpdateformData.append('Category', selectedRowData.Category);
    UpdateformData.append('Technology', selectedRowData.Technology);
    UpdateformData.append('SenderName', instance.getAllAccounts()[0]?.name);
    UpdateformData.append('inputJson', files.JsonFile)
    UpdateformData.append('UploadIcon', files.UploadIcon)
    UpdateformData.append('UploadBGIcon', files.UploadBGIcon)

    const requestOptions = {
      method: "PUT",
      body: UpdateformData,
    };

    try {
      console.log("Sending request with data:", UpdateformData);

      const response = await fetch(
        `https://prod.appstudioltts.com/api/linkstateupdate/updatedata/${selectedRowData._id}`,
        requestOptions
      );

      console.log("Response status:", response.status);

      if (response.ok) {
        const responseData = await response.json();

        setFormData({
          JsonFile: "",
          GitHubLink: "",
          OpenSrcLib: "",
          HostedURL: "",
          DesignDocs: "",
          HowToUseHURL: "",
          ChooseVertical: "",
          TypeOfComponent: "",
          UploadIcon: "",
          UploadBGIcon: "",
          checkbox: false,
        });
        setSelectedRowData(null);

        const designDocsInput = document.getElementById("DesignDocs");
        const howToUseHURLInput = document.getElementById("HowToUseHURL");

        if (designDocsInput) {
          designDocsInput.value = "";
        }
        if (howToUseHURLInput) {
          howToUseHURLInput.value = "";
        }
        if (response.ok) {
          toast.success("Form submitted successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          throw new Error("Failed to submit the form", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit form", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    setUploadSuccess(false)
    setOpenMyComponentsDialog(false);
  };
  // -----------------------------------------------------------------

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancelLinks = () => {
    console.log("Form canceled");
  };

  const isFormValid = () => {
    return (
      formData.JsonFile !== "" &&
      formData.GitHubLink !== "" &&
      formData.HostedURL !== "" &&
      formData.OpenSrcLib !== "" &&
      formData.DesignDocs !== "" &&
      formData.HowToUseHURL !== "" &&
      formData.ChooseVertical !== "" &&
      formData.TypeOfComponent !== "" &&
      formData.UploadIcon !== "" &&
      formData.UploadBGIcon !== "" &&
      formData.checkbox
    );
  };

  const isGitHubURL = (input) => {
    return input.toLowerCase().includes("github");
  };
  const [buttonClick, setButtonClick] = useState(false);
  async function AddComponentToPlayground(selectedRow) {
    setButtonClick(true)
    console.log("Selected ROw is ",selectedRow," ID id : ",selectedRow._id);
    AddedStateUpdate(selectedRow._id);

    const response = await fetch('https://prod.appstudioltts.com/api/auto/fetch-data');
    const CategoryDetailsResponse = await response.json();

    const componentResponse = await fetch('https://prod.appstudioltts.com/api/linkstateupdate/fetchdata');
    const ContributeComponentResponse = await componentResponse.json();

    const matchingApiData = CategoryDetailsResponse[0].AutoData.find((apiData) => (apiData.label.replace(/\s/g, '').toLowerCase() === selectedRow.Category.toLowerCase()))
    const matchingContributeApiData = ContributeComponentResponse.find((apiData) => apiData.ComponentName === selectedRow.ComponentName && apiData.Category === selectedRow.Category);
    console.log("matchingAPI : ", matchingApiData, selectedRow)
    let UpdateComponentDetails;
    if(selectedRow.Technology.toLowerCase() == 'react')
    {
      // If the component to be added is REACT
      if (matchingApiData != undefined) {
        // For exsisting Category
        UpdateComponentDetails = {
          "AutoData": [
            {
              "label": `${matchingApiData.label}`,
              "rootPath": `${matchingApiData.rootPath}`,
              "hostedurlVal": `${matchingApiData.hostedurlVal}`,
              "chartType": `${matchingApiData.chartType}`,
              "type": `${matchingApiData.type}`,
              "packageName" : `${matchingApiData.packageName}`,
              "libraryGitURL": `${matchingApiData.libraryGitURL}`,
              "libraryDocURL": `${matchingApiData.libraryDocURL}`,
              "subComponents":
                [
                  {
                    "subCompoLabel": `${matchingContributeApiData.ComponentName}`,
                    "routePath": `${matchingContributeApiData.ComponentName.trim()}`,
                    "hostedurlVal": `${matchingContributeApiData.ComponentName.trim()}Img`,
                    "TypeFilter": "",
                    "Icon": `${selectedRow.UploadIcon}`,
                    "activeIcon": `${selectedRow.UploadBGIcon}`,
                    "designDocUrl" : `${selectedRow.designDocuments}`

                  }
                ]
            }
          ]
        }
      }
      else {
        // For creating new Category
        UpdateComponentDetails = {
          "AutoData": [
            {
              "label": `${selectedRow.Category}`,
              "rootPath": `/${selectedRow.ComponentName.replace(/\s/g, '')}`,
              "hostedurlVal": `${selectedRow.ComponentName.replace(/\s/g, '')}`,
              "chartType": `${selectedRow.ComponentName.replace(/\s/g, '_')}`,
              "type": `${selectedRow.type == "nonui" ? "ui" : selectedRow.type}`,
              "packageName" : `@ltts-dtp-appstudio/react-${selectedRow.ComponentName.trim().toLowerCase()}`,
              "libraryGitURL": `${selectedRow.PublishPackageUrl}`,
              "libraryDocURL": "https://webappstudio-react.visualstudio.com/WebAppStudio-React/_wiki/wikis/WebAppStudio-React.wiki/58/ReferenceDoc-HowToUseAppStudioLibraries",
              "subComponents":
                [
                  {
                    "subCompoLabel": `${matchingContributeApiData.ComponentName}`,
                    "routePath": `${matchingContributeApiData.ComponentName.replace(/\s/g, '')}`,
                    "hostedurlVal": `${matchingContributeApiData.ComponentName.replace(/\s/g, '')}Img`,
                    "TypeFilter": "",
                    "Icon": `${selectedRow.UploadIcon}`,
                    "activeIcon": `${selectedRow.UploadBGIcon}`,
                    "designDocUrl" : `${selectedRow.designDocuments}`
                  }
                ]
            }
          ]
        }
      }
    }
    else if(selectedRow.Technology.toLowerCase() == 'angular')
    {
      // If the component to be added is ANGULAR
      if (matchingApiData != undefined) {
        // For exsisting Category
        UpdateComponentDetails = {
          "AutoData": [
            {
              "label": `${matchingApiData.label}`,
              "rootPath": `${matchingApiData.rootPath}`,
              "hostedURL": `${matchingApiData.hostedURL}`,
              "hostedurlVal": `${matchingApiData.hostedurlVal}`,
              "hostedurlInfo": `${matchingApiData.hostedurlInfo}`,
              "chartType": `${matchingApiData.chartType}`,
              "type": `${matchingApiData.type}`,
              "subComponents":
                [
                  {
                    "subCompoLabel": `${matchingContributeApiData.ComponentName}`,
                    "routePath": `${matchingContributeApiData.ComponentName.trim()}`,
                    "hostedurlVal": `${matchingContributeApiData.ComponentName.trim()}Img`,
                    "TypeFilter": "",
                    "Icon": `${matchingContributeApiData.ComponentName.trim()}`,
                    "activeIcon": `${matchingContributeApiData.ComponentName.trim()}Active`
                  }
                ]
            }
          ]
        }
      }
      else {
        // For creating new Category
        UpdateComponentDetails = {
          "AutoData": [
            {
              "label": `${selectedRow.Category}`,
              "rootPath": `/${selectedRow.ComponentName.replace(/\s/g, '')}`,
           //   "hostedURL": `${selectedRow.ComponentName.replace(/\s/g, '').toLowerCase()}HostedURL`,
              "hostedurlVal": `${selectedRow.ComponentName.replace(/\s/g, '')}Img`,
           //   "hostedurlInfo": `${selectedRow.ComponentName.replace(/\s/g, '')}InfoURL`,
              "chartType": `${selectedRow.ComponentName.replace(/\s/g, '_')}`,
              "type": `${selectedRow.type == "nonui" ? "ui" : selectedRow.type}`,
              "subComponents":
                [
                  {
                    "subCompoLabel": `${matchingContributeApiData.ComponentName}`,
                    "routePath": `${matchingContributeApiData.ComponentName.replace(/\s/g, '')}`,
                    "hostedurlVal": `${matchingContributeApiData.ComponentName.replace(/\s/g, '')}Img`,
                    "TypeFilter": "",
                    "Icon": `${matchingContributeApiData.ComponentName.replace(/\s/g, '')}`,
                    "activeIcon": `${matchingContributeApiData.ComponentName.replace(/\s/g, '')}Active`,
                    "designDocUrl" : `${selectedRow}`
                  }
                ]
            }
          ]
        }
      }
    }

    let apiURL;
    selectedRow.Technology.toLowerCase() == 'react' ? apiURL = `https://prod.appstudioltts.com/api/auto/add-subcomponent`
      : apiURL = `https://prod.appstudioltts.com/api/angularauto/add-subcomponent`

    // apiURL = `https://prod.appstudioltts.com/api/auto/add-subcomponent`
    fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(UpdateComponentDetails)
    })
      .then(response => {
        if (response.status == 200)
          return (
            setAddtoPlaygroundbuttonClick({
              addingState: true,
              selectedRowId: `${selectedRow._id}`
            }),
            Swal.fire({
              title: "Information!",
              html: `For instructions on adding Component to the Playground, click <a href="https://dev.azure.com/WebAppStudio-React/WebAppStudio-React/_wiki/wikis/WebAppStudio-React.wiki/293/Steps-to-Add-Component-to-Playground" target="_blank">here</a> `,
              icon: "info",
              confirmButtonText: "OK",
            }),
            response.json()
          )
        else
          return (
            setAddtoPlaygroundbuttonClick(false),
            response.json()
          )
      },
      setButtonClick(false)
      )
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }

  const handleOpenDropdown = (rowId, component, componentState) => {
    console.log("Handle Open dropdown is activated")
    setOpenDropdownRow(rowId);
    setItemVal(component)
    setSelectedComponentState(componentState);
    //  setFilterByComponentState(componentState)
    setIsOpen(!isOpen)
  };

  const handleAddComponentToPlayground = (row) => {
    return AddComponentToPlayground(row);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeP = (e) => {
    const { name, value } = e.target;
    setnewFormData({
      ...newformData,
      [name]: value,
    });
  };

  //+Components close
  const CloseComponentPopup = () => {
    setComponentpopup(false);
  };

  const handleCloseLinksDialog = () => {
    setOpenLinksDialog(false);
  };

  const handleAddToPlayground = () => {
    // console.log("Add to Playground clicked for row:", rowData);
    // Add your logic here for handling the "Add to Playground" action
    Swal.fire({
      title: "Information!",
      html: `For instructions on adding Component to the Playground, click <a href="https://dev.azure.com/WebAppStudio-React/WebAppStudio-React/_wiki/wikis/WebAppStudio-React.wiki/293/Steps-to-Add-Component-to-Playground" target="_blank">here</a> `,
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (state) => {
    console.log("HandleValue is ",state)
    setFilterByComponentState(state)
    setAnchorEl(null);
  };

  // console.log("Table Data",tableData,filteredTableData)
  console.log("Button clicked", buttonClick)

  return (
    <div classname="componentsPage">
      {/* <Grid container >
        <Grid item className="mybtnWrapper"> */}
        <div className="myComponentsButtonDiv">
        <Button variant="outlined" color="primary" className="compoBtn" onClick={() => setComponentpopup(true)}><AddIcon sx={{ width: '20px', fontSize: '15px', marginTop: '-1px' }} /><p className='openComponentsButtonPtag' >Component</p></Button>
        {/* <Button variant="outlined" color="primary" className="compoBtn" onClick={handleAddToPlayground}><AddIcon sx={{ width: '20px', fontSize: '15px', marginTop: '-1px' }} /><p className='openComponentsButtonPtag' >Component</p></Button> */}
        </div>
        {/* </Grid>
      </Grid> */}

        <Paper className='componentsPaper'>
          <TableContainer sx={{borderBottom:'transparent !important'}}>
            <Table stickyHeader aria-label="sticky table" sx={{borderBottom:'none'}}>
              <TableHead className='mobile-view myComponentsTableHead' style={{ display: 'block', maxHeight: 240, overflow: 'auto'}}>
                <TableRow style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                  {columns.map((column) => {
                    if (column.id === 'ComponentDetails') {
                      return (
                        <TableCell key={column.id} align={column.align} style={{ backgroundColor: 'lightblue', color: 'black', fontWeight: 'bold', width: '20%' }}>
                          {column.label}
                        </TableCell>
                      )
                    }
                    else if (column.id === 'ComponentState') {
                      return (
                        <TableCell align="left" style={{width:"15%"}}>
                          ComponentState
                          <IconButton onClick={handleMenuOpen}> <ArrowDropDownIcon /> </IconButton>
                          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem value="" onClick={() => handleMenuItemClick("")}> All </MenuItem>
                            {uniqueComponentStates.map((state) => (
                              <MenuItem key={state} value={state} onClick={() => handleMenuItemClick(state)}>
                                {state}
                              </MenuItem>
                            ))}
                          </Menu>
                        </TableCell>
                      )
                    }
                    else {
                      return (
                        <TableCell key={column.id} align={column.align}
                         style={{ minWidth: column.minWidth, backgroundColor: 'lightblue', color: 'black', fontWeight: 'bold', width:column.id === 'ComponentName' ? "15%" : column.id === 'Category' ? "13%" : column.id === 'no' ? "6%" :  column.id=== 'Technology' ? "14%" : column.id === 'actions' ? "13%" : null }}>
                          {column.label}
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              </TableHead>
            {isLoading ? (
              <TableBody style={{ display: 'block', maxHeight: 240, overflow: 'auto'}}>
                <TableRow style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                  <TableCell colSpan={columns.length} align="center">
                    <div className="loading-symbol"></div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody style={{ display: 'block', maxHeight: 240, overflow: 'auto'}}>
                {filteredTableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const adjustedIndex = page * rowsPerPage + index + 1;
                    console.log("Row is :",row, row.ComponentState, row["ComponentState"])
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                        {columns.map((column, i) => {
                          if (column.id == "no") {
                            return (
                              <>
                                <TableCell style={{width:"6%"}}>{adjustedIndex}</TableCell>
                              </>
                            );
                          }
                          else if (column.id == "actions") {
                            return (
                              <>
                                <TableCell style={{width:"12%"}}>
                                  {row["ComponentState"] != undefined && row["ComponentState"] != null ?
                                    row["ComponentState"].toLowerCase() == "published" ?
                                      <MoreVertIcon
                                        onClick={(e) => {
                                          setDropdownPosition({ top: e.clientY, left: e.clientX })
                                          openDropdownRow === index
                                            ? handleCloseDropdown()
                                            : handleOpenDropdown(row["_id"], row, row["ComponentState"])
                                        }}
                                      />
                                      :
                                      row["ComponentState"].toLowerCase() == "added to playground" ? <MoreVertIcon disabled color="disabled" />
                                      :
                                      row["ComponentState"].toLowerCase() == "adding to playground" ? <MoreVertIcon onClick={handleAddToPlayground}/>
                                      :
                                        <MoreVertIcon
                                          onClick={(e) => {
                                            setDropdownPosition({ top: e.clientY, left: e.clientX })
                                            openDropdownRow === index
                                              ? handleCloseDropdown()
                                              : handleOpenDropdown(row["_id"], row, row["ComponentState"])
                                          }}
                                        />
                                    :
                                    <MoreVertIcon disabled color="disabled" />
                                  }
                                </TableCell>
                              </>
                            )
                          }

                          else {
                            const value = row[column.id];
                            return (
                              <>
                                <TableCell key={column.id} style={{width:column.id === 'ComponentName' ? "15%" : column.id === 'Category' ? "13%"  :  column.id === 'Technology' ? "14%" : column.id === 'ComponentDetails' ? "20%" :column.id === 'ComponentState' ? "15%" : null}} >
                                  {value}
                                </TableCell>
                              </>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>)}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredTableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="paginationWrapper"
          />
        </Paper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className="headerTxt">
          <p></p>
        </Grid>
        <Grid item className="footerbtnWrap">
          <NavLink to="/home">
            <Button variant="outlined" color="primary" className="openComponentsCloseButton" sx={{ fontSize: '13px', marginRight: '10px', padding: "6px 24px" }}> Close </Button>
          </NavLink>
        </Grid>
      </Grid>

      {/* Actions submit/remove PopUp */}
      {openDropdownRow && (
        <div className="dropDowndiv" style={selectedComponentState != "Published" ? styli : playgroundAddStyli} onBlur={() => setIsOpen(false)} tabIndex="0" autoFocus ref={dropdownRef}>
          <Button
            variant="contained"
            onClick={() => { selectedComponentState != "Published" ? handleOpenMyComponentsDialog(itemVal) : AddComponentToPlayground(itemVal) }}
            style={{
              fontSize: "12px",
              padding: "9px",
              width: selectedComponentState != "Published" ? "99px" : "195px",
              borderRadius: 0,
              backgroundColor:
                selectedComponentState === "Ready to Review" ||
                  selectedComponentState === "Rejected"
                  ? "#e0e0e0"
                  : "white",
              color:
                selectedComponentState === "Ready to Review" ||
                  selectedComponentState === "Rejected"
                  ? "#bababa"
                  : "black",

              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => {
              if (
                !(
                  selectedComponentState ===
                  "Ready to Review" ||
                  selectedComponentState === "Rejected"
                )
              ) {
                e.currentTarget.style.backgroundColor =
                  "lightblue";
              }
            }}
            onMouseOut={(e) => {
              if (
                !(
                  selectedComponentState ===
                  "Ready to Review" ||
                  selectedComponentState === "Rejected"
                )
              ) {
                e.currentTarget.style.backgroundColor = "white";
              }
            }}
            disabled={
              selectedComponentState === "Ready to Review" ||
              selectedComponentState === "Published"
              // selectedComponentState === "Published"
            } // Disable if component state is 'Ready to Review'
          >

            {selectedComponentState != "Published" ? <img
              src={Icon}
              alt="Submit"
              style={{
                marginRight: "10px",
                opacity:
                  selectedComponentState ===
                    "Ready to Review" ||
                    selectedComponentState === "Rejected"
                    ? 0.5
                    : 1,
              }}
            />
              :
              <DoneIcon
                style={{
                  marginRight: "10px",
                  opacity:
                    selectedComponentState ===
                      "Ready to Review" ||
                      selectedComponentState === "Rejected"
                      ? 0.5
                      : 1,
                }}
              />
            }
            {selectedComponentState != "Published" ? `Submit` : `Add to Playground`}
          </Button>
          <Button
            variant="contained"
            style={{
              display: selectedComponentState == "Published" ? "none" : ""
            }}
            onClick={() => { handleRemoveRow(itemVal._id)}}
            className="RemoveButtonDropdown"
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor =
              "lightblue")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "white")
            }
          >
            <img
              src={Icon2}
              alt="Remove"
              className="removeIconContribute"
            />
            Remove
          </Button>
        </div>
      )}
      {/* ---------------------------------- */}

      {/* Submit dialog PopUp */}

      <Dialog
        open={openMyComponentsDialog}
        onClose={handleCloseMyComponentsDialog}
        maxWidth="lg"
      >
        <DialogTitle className="compoDialogTitle">
          <p className="dialogheading">
            {" "}
            Select from the below listed Things
          </p>
          <CloseButton
            aria-label="close"
            onClick={handleCloseMyComponentsDialog}
          >
            <CloseIcon />
          </CloseButton>

          {/* ... (other form groups) */}
        </DialogTitle>
        <DialogContent className="inProgresscontent">
          <div className="form">
            <div className="form-group">
              <label htmlFor="GitHubLink">
                Github URL for individual component{" "}
                <span className="spanStyleStarCont">*</span>{" "}
              </label>
              <input
                type="text"
                id="GitHubLink"
                name="GitHubLink"
                value={formData.GitHubLink}
                onChange={handleChangeForm}
                placeholder="URL"
              />

              {formData.GitHubLink &&
                (isURL(formData.GitHubLink) ? (
                  isGitHubURL(formData.GitHubLink) ? (
                    <CheckCircleOutline className="validation-icon valid" />
                  ) : (
                    <HighlightOff className="validation-icon invalid" />
                  )
                ) : (
                  <HighlightOff className="validation-icon invalid " />
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="HostedURL">
                Hosted URL <span className="spanStyleStarCont">*</span>{" "}
              </label>
              <input
                type="text"
                id="HostedURL"
                name="HostedURL"
                value={formData.HostedURL}
                onChange={handleChangeForm}
                placeholder="URL"
              />
              {formData.HostedURL &&
                (isURL(formData.HostedURL) ? (
                  <CheckCircleOutline className="validation-icon valid" />
                ) : (
                  <HighlightOff className="validation-icon invalid" />
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="DesignDocs">
                Design Document <span className="spanStyleStarCont">*</span>{" "}
              </label>
              <input
                type="text"
                id="DesignDocs"
                name="DesignDocs"
                value={formData.DesignDocs}
                onChange={handleChangeForm}
                placeholder="Enter Design Document URL"
              />

              {formData.DesignDocs &&
                (isURL(formData.DesignDocs) ? (
                  <CheckCircleOutline className="validation-icon valid" />
                ) : (
                  <HighlightOff className="validation-icon invalid" />
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="HowToUseHURL">
                Document on how to use hosted URL{" "}
                <span className="spanStyleStarCont">*</span>{" "}
              </label>
              <input
                type="text"
                id="HowToUseHURL"
                name="HowToUseHURL"
                value={formData.HowToUseHURL}
                onChange={handleChangeForm}
                placeholder="Enter Document URL"
              />

              {formData.HowToUseHURL &&
                (isURL(formData.HowToUseHURL) ? (
                  <CheckCircleOutline className="validation-icon valid" />
                ) : (
                  <HighlightOff className="validation-icon invalid" />
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="OpenSrcLib">
                The Component provided above is SonarQube tested{" "}
                <span className="spanStyleStarCont">*</span>{" "}
              </label>
              <input
                type="text"
                id="OpenSrcLib"
                name="OpenSrcLib"
                value={formData.OpenSrcLib}
                onChange={handleChangeForm}
                placeholder="Enter SonarQube tested URL"
              />
              {formData.OpenSrcLib &&
                (isURL(formData.OpenSrcLib) ? (
                  <CheckCircleOutline className="validation-icon valid" />
                ) : (
                  <HighlightOff className="validation-icon invalid" />
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="UploadIcon">Input is a JSON File <span className="spanStyleStarCont">*</span></label>
              <input type="file" id="JsonFile" name="JsonFile" accept=".json" onChange={handleFileChange} />
              <Tooltip title="The file type should be JSON" placement="bottom-end">
                <img src={InfoIcon} alt="Info" className="info-icon-2" />
              </Tooltip>
            </div>

            <div className="form-group">
              <label htmlFor="UploadIcon">Default Icon <span className="spanStyleStarCont">*</span></label>
              <input type="file" id="UploadIcon" name="UploadIcon" accept="image/*" onChange={handleFileChange} />
              <Tooltip title="Please upload the Icon with the same pattern followed by Appstudio/Playground in JPG, PNG or JPEG format." placement="bottom-end">
                <img src={InfoIcon} alt="Info" className="info-icon-2"/>
              </Tooltip>
            </div>

            <div className="form-group">
              <label htmlFor="UploadBGIcon">Active Icon <span className="spanStyleStarCont">*</span></label>
              <input type="file" id="UploadBGIcon" name="UploadBGIcon" accept="image/*" onChange={handleFileChange} />
              <Tooltip title="Please upload the Icon with the same pattern followed by Appstudio/Playground in JPG, PNG or JPEG format." placement="bottom-end">
                <img src={InfoIcon} alt="Info" className="info-icon-2" />
              </Tooltip>
            </div>

            <div className="my-comp-div-1">
              <label className="typ-of-comp">Type of component <span className="spanStyleStarCont">*</span></label>
              <div className="my-comp-r">
                <label className="my-comp-l">
                  <input type="radio" name="TypeOfComponent" value="ui" checked={formData.TypeOfComponent === "ui"} onChange={handleChangeForm} />
                  UI
                </label>
                <label className="my-comp-l1">
                  <input type="radio" name="TypeOfComponent" value="nonui" checked={formData.TypeOfComponent === "nonui"} onChange={handleChangeForm} />
                  Non-UI
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="ChooseVertical"> Vertical <span className="spanStyleStarCont">*</span></label>
              <select id="ChooseVertical" name="ChooseVertical" value={formData.ChooseVertical} onChange={handleChangeForm}>
                <option value="">Select</option>
                <option value="Medical">Medical</option>
                <option value="Industial Products">Industial Products</option>
                <option value="DMS">DMS</option>
                <option value="PE">PE</option>
                <option value="CTO">CTO</option>
                <option value="DPS">DPS</option>
                <option value="HiTech">HiTech</option>
                <option value="Next Gen Communications">Next Gen Communications</option>
                <option value="Transportation">Transportation</option>
                <option value="V&V/Emb">V&V/Emb</option>
                <option value="Consumer Electronics">Consumer Electronics</option>
              </select>
            </div>

            <div className="codeOpenSrcLib">
              <label htmlFor="checkbox"> </label>
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                checked={formData.checkbox}
                onChange={handleCheckboxChange}
              />
              Code is using only open source libraries{" "}
              <span className="spanStyleStarCont">*</span>{" "}
            </div>
          </div>
          <ToastContainer />
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelBtnLinksPopup"
            variant="outlined"
            onClick={handleCloseMyComponentsDialog}>
            Cancel
          </Button>

          <Button
            className="submitBtnLinksPopup"
            variant="contained"
            onClick={handleSubmitLinks}
            disabled={!isFormValid()}>
            Submit
          </Button>
        </DialogActions>

      </Dialog>
      {/* ---------------------------------- */}

      {/* Dialog Popup for Publish Package URL */}
      <Dialog
        open={openLinksDialog}
        onClose={handleCloseLinksDialog}
        className = "SubmitDialog">
        <DialogTitle className="compoDialogTitle">
          <p className="dialogheading">
            {" "}
            Publish Package URL
          </p>
          <CloseButton aria-label="close" onClick={handleCloseLinksDialog} >
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
        <DialogContent className="inProgresscontent">
          <div className="form">
            <div className="form-group">
              <label htmlFor="PackageURL">
                Publish Package URL <span className="spanStyleStarCont">*</span>{" "}
              </label>
              <input
                type="text"
                id="PackageURL"
                name="PackageURL"
                value={newformData.PackageURL}
                onChange={handleChangeP}
                placeholder="Enter Publish Package URL"
              />
              {newformData.PackageURL &&
                (isURL(newformData.PackageURL) ? (
                  <CheckCircleOutline className="validation-icon valid" />
                ) : (
                  <HighlightOff className="validation-icon invalid" />
                ))}
            </div>
          </div>
          <ToastContainer />
        </DialogContent>

        <DialogActions>
          <Button
            className="cancelBtnLinksPopup"
            variant="outlined"
            onClick={handleCloseLinksDialog}
          >
            Cancel
          </Button>
          <Button
            className="submitBtnLinksPopup"
            variant="contained"
            onClick={handleSubmitP}
            disabled={!isFormValidP()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* ---------------------------------- */}

      {/* Add Component onClick PopUp */}
      <AddComponent open={ComponentPopup} handleClose={CloseComponentPopup} flagVal={currentTab} />
      {/* ---------------------------------- */}
    </div>
  );
}