import { Typography } from '@mui/material';
import './contribute.css';
import AppLogo from "../../Icons/appLogo.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React, { useState, useEffect, useRef } from "react";
import OpenComponent from './OpenComponents';
import MyComponent from './MyComponents';
import { useMsal } from '@azure/msal-react';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const App = () => {
    // const { instance } = useMsal();
    // const [employeeData, setEmployeeData] = useState(null);
    const [value, setValue] = React.useState(0);
    const [isTab2Active, setIsTab2Active] = useState(false);

     //AuthGuard
    //  useEffect(() => {
    //     const userEmail = instance.getAllAccounts()[0]?.username;
    
    //     if (userEmail) {
    //       fetch(`https://prod.appstudioltts.com/api/empdata/employees/${userEmail}`)
    //         .then((response) => response.json())
    //         .then((data) => {
    //           localStorage.setItem("employeeData", JSON.stringify(data));
    //           setEmployeeData(data);
    //         })
    //         .catch((error) => {
    //           console.error("Error fetching employee data:", error);
    //         });
    //     }
    
    //     // else{
    //     //   window.location.replace('http://localhost:3000/');
    //     //   window.location.replace('https://dev.communityportal.appstudioltts.com/');
    //     // }
    
    //     else{
    //       const DEV = "https://dev.communityportal.appstudioltts.com/";
    //       const PROD = "https://communityportal.appstudioltts.com/";  
    
    //       var urlIs =  window.location.href;
    //       if(urlIs.includes(DEV))
    //       {
    //         window.location.replace('https://dev.communityportal.appstudioltts.com/')
    //       }
    //       else if(urlIs.includes(PROD))
    //       {
    //         window.location.replace('https://communityportal.appstudioltts.com/')
    //       }
    //     }
    //   }, []);

    const handleChange = (event,newValue) => {
        setValue(newValue);
            if (newValue === 1) {
                setIsTab2Active(true);
            } else {
                setIsTab2Active(false);
            }
        // setCurrentTab(newValue);
    };
    
    return (
        <div className="app-container">
         <AppBar position="static" className="HeaderCss componentsHeader">
                <Toolbar>
                    <span>
                        <img className="AppLogo" src={AppLogo} alt="App Logo" />
                    </span>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Pending Components List...
                    </Typography>
                </Toolbar>
            </AppBar>
    
            <Box className="tabContainer">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab className='tabContainerTab' label="Open Components"  {...a11yProps(0)}  />
                        <Tab className='tabContainerTab' label="My Components"  {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} hidden={value !== 0}>
                    <OpenComponent tabVal={isTab2Active}/>
                    </CustomTabPanel >
                    <CustomTabPanel value={value} index={1} hidden={value !== 1}>
                        <MyComponent tabVal={isTab2Active}/>
                    </CustomTabPanel>
        </div>
    );
};

export default App;

