import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Grid, Button } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterPopup from './FilterPopup';
import AddComponent from '../Global/AddComponent';
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-grid-system";
import './contribute.css';
 
const columns = [
    { id: 'select', label: 'Select' },
    { id: 'no', label: 'Sl No', padding: '0px 10px 0px 10px' },
    { id: 'ComponentName', label: 'Component Name', width:'25%'},
    { id: 'ComponentDetails', label: 'Component Details'},
    { id: 'Category', label: 'Category' },
    { id: 'Technology', label: 'Technology' }
];
 
 
export default function StickyHeadTable() {
    const currentTab = 0;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [myFilterTableData, setMyFilterTableData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [ComponentPopup, setComponentpopup] = useState(false);
    const [openFilterPopup, setOpenFilterPopup] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedTechnology, setSelectedTechnology] = useState("");
    const [selectedComponentName, setSelectedComponentName] = useState("");
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(true);
 
 
    const TableDataFetch = async () => {
        setIsLoading(true)
        const currentUserEmail = instance.getAllAccounts()?.[0]?.username;
        setFilteredTableData([])
        try {
            fetch("https://prod.appstudioltts.com/api/request/")
                .then((response) => response.json())
                .then((data) => {
                    console.log("data from open components is", data);
                    setTableData(data);
                            setFilteredTableData(data);
                            setIsLoading(false)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setIsLoading(false)
                });
        }
        catch (error) {
            console.log("Error occured while fetching", error)
            setIsLoading(false)
        }
        // setLoading(false)...
    }
 
    useEffect(() => {
        TableDataFetch()
    }, [])
 
    const isSelected = (rowId) => selectedRows.indexOf(rowId) !== -1;
 
    const handleRowSelect = (rowId) => {
        const selectedIndex = selectedRows.indexOf(rowId);
        let newSelected = [];
 
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, rowId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelected);
    };
 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
 
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
 
    const handleOpenFilterPopup = () => {
        setOpenFilterPopup(true);
    };
 
    const handleCancel = () => {
        console.log("cancel button clicked!");
    };
 
    const handleCloseFilterPopup = () => {
        setOpenFilterPopup(false);
    };
 
    const uniqueCategories = Array.from(
        new Set(tableData.map((item) => item["Category"]))
    );
    const uniqueTechnologies = Array.from(
        new Set(tableData.map((item) => item["Technology"]))
    );
    const uniqueComponentNames = Array.from(
        new Set(tableData.map((item) => item["ComponentName"]))
    );
 
    useEffect(() => {
        const uniqueCategories = Array.from(
            new Set(tableData.map((item) => item["Category"]))
        );
        const uniqueTechnologies = Array.from(
            new Set(tableData.map((item) => item["Technology"]))
        );
        const uniqueComponentNames = Array.from(
            new Set(tableData.map((item) => item["ComponentName"]))
        );
    }, [tableData]);
 
    // Filtering table data based on selected category or technology
    useEffect(() => {
        let filteredData = [...tableData];
 
        if (selectedCategory !== "") {
            filteredData = filteredData.filter(
                (item) => item["Category"] === selectedCategory
            );
        }
 
        if (selectedTechnology !== "") {
            filteredData = filteredData.filter(
                (item) => item["Technology"] === selectedTechnology
            );
        }
 
        if (selectedComponentName !== "") {
            filteredData = filteredData.filter(
                (item) => item["ComponentName"] === selectedComponentName
            );
        }
 
        setFilteredTableData(filteredData);
    }, [selectedCategory, selectedTechnology, selectedComponentName, tableData]);
   
    // After onclick on  Work On!
    const handleSubmit = () => {
        const selectedRowsData = tableData.filter((item, index) =>
            isSelected(index)
        );
        const selectedIds = selectedRowsData.map((selectedRow) => selectedRow._id);
        if (selectedRowsData.length === 0) {
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 3000);
            return;
        }
        Promise.all(
            selectedIds.map((selectedId) => {
                return fetch(
                    `https://prod.appstudioltts.com/api/request/${selectedId}`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error("Error deleting data:", error);
                        return null;
                    });
            })
        )
            .then(() => {
                setSelectedRows([]); 
                //Refetch the data to update the table after deletion
                fetch("https://prod.appstudioltts.com/api/request/")
                    .then((response) => response.json())
                    .then((data) => {
                        setTableData(data);
                        setFilteredTableData(data);
                    })
                    .catch((error) => {
                        console.error("Error fetching data", error);
                    });
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
        // To add selected component in My Components
        Promise.all(
            selectedRowsData.map((selectedRow) => {
                const rowData = {
                    ComponentName: selectedRow["ComponentName"],
                    ComponentDetails: selectedRow["ComponentDetails"],
                    Category: selectedRow["Category"],
                    Technology: selectedRow["Technology"],
                    SenderName: instance.getAllAccounts()?.[0]?.name,
                    Email: instance.getAllAccounts()?.[0]?.username,
                    ComponentState: "In Progress",
                };
 
                return fetch(
                    "https://prod.appstudioltts.com/api/linkstateupdate/submitdata",
                    // "https://prod.appstudioltts.com/api/topinvest/entities1",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(rowData),
                    }
                )
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error("Error sending data:", error);
                        return null;
                    });
            })
        )
            .then(() => {
                setShowSuccessNotification(true);
                
                toast.success("Component added to MyComponents successfully!", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                  });
                
                setTimeout(() => {
                    setShowSuccessNotification(false);
                    setSelectedRows([]);
                }, 4000);
 
                //send email to the user
                const userEmail = instance.getAllAccounts()?.[0]?.username;
                fetch(
                    "https://prod.appstudioltts.com/api/contribution-mail/send-email",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ email: userEmail }),
                    }
                )
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("Email sent successfully: ", data);
                    })
                    .catch((error) => {
                        console.error("Error sending mail: ", error);
                    });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
 
    //+Components close
    const CloseComponentPopup = () => {
        setComponentpopup(false);
      };  
 
    return (
        <div classname="componentsPage">
                <Row className='openComponentsButtonRow'>
                    <Col className='openComponentsButtonCol' xs={12} sm={6} md={7} lg={7} xl={7}>
                        <p className='openComponentsCaption'>Please select the component to work on..</p>
                    </Col>
                    <Col className='openComponentsButtonsDiv' xs={12} sm={6} md={5} lg={5} xl={5}>
                        <Button variant="outlined" color="primary" className="compoBtn" onClick={() => setComponentpopup(true)}><AddIcon sx={{ width: '20px', fontSize: '15px', marginTop: '-1px' }} /><p className='openComponentsButtonPtag' >Component</p></Button>
                        <Button variant={openFilterPopup?"contained": "outlined" } color="primary" className="filterBtn"  onClick={handleOpenFilterPopup}><FilterAltIcon sx={{ width: '20px', fontSize: '13px', marginTop: '-1px' }} /><p className='openComponentsButtonPtag' > Filter</p></Button>
                    </Col>
                </Row>
                <Paper className='componentsPaper'>
                    <TableContainer sx={{ maxWidth: '100%',overflow: 'auto'}}>
                    <Table stickyHeader aria-label="sticky table" sx={{width: '100%',tableLayout: 'fixed'}}>
                        <TableHead className='mobile-view' style={{ display: 'block', maxHeight: 240, overflow: 'auto'}}>
                        <TableRow style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                {columns.map((column) => {
                                        return (
                                            <TableCell key={column.id} align={column.align} className="openComponentsTableCell"
                                                style={{minWidth: column.minWidth, backgroundColor: 'lightblue', color: 'black', fontWeight: 'bold', width:column.id === 'ComponentDetails' ? "25%" : column.id === 'select' ? "7%" : column.id === 'no' ? "5%" : null }}>
                                                {column.label}
                                            </TableCell>
                                        )
                                })}
                        </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableBody style={{ display: 'block', maxHeight: 240, overflow: 'auto'}}>
                                <TableRow style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                    <TableCell colSpan={columns.length} align="center">
                                        <div className="loading-symbol"></div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody style={{ display: 'block', maxHeight: 240, overflow: 'auto'}}>
                                {filteredTableData .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                                {columns.map((column, i) => {
                                                    if (column.id == "no") {
                                                        return (
                                                            <>
                                                                <TableCell className="openComponentsTableCell" style={{width:"5%"}}>{index + 1}</TableCell>
                                                            </>
                                                        );
                                                    }
                                                    else if (column.id == "actions") {
                                                        return (
                                                            <>
                                                                <TableCell className="openComponentsTableCell" style={{width:"7%"}}> <MoreVertIcon /> </TableCell>
                                                            </>
                                                        )
                                                    }
                                                    else if (column.id == "select") {
                                                        return (
                                                            <>
                                                                <TableCell className="openComponentsTableCell" style={{width:"7%"}}> <input type="checkbox" onChange={() => handleRowSelect(index)} checked={isSelected(index)} /></TableCell>
                                                            </>
                                                        )
                                                    }
                                                    else {
                                                        const value = row[column.id];
                                                        return (
                                                            <>
                                                                <TableCell key={column.id} className="openComponentsTableCell" style={{width:column.id === 'ComponentDetails' ? "25%" : null}}>
                                                                    {value}</TableCell>
                                                            </>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>)}
                    </Table>
                     </TableContainer>
 
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredTableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="paginationWrapper"/>
               </Paper>
 
               {/* Footer Buttons Grid */}
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item className="headerTxt">
                        <p></p>
                    </Grid>
                    <Grid item className="footerbtnWrap">
                        <NavLink to="/home">
                            <Button variant="outlined" color="primary" className="openComponentsCloseButton" sx={{ fontSize: '13px', marginRight: '10px', padding: "6px 24px" }}> Close </Button>
                        </NavLink>
                        <Button variant="contained" onClick={handleSubmit} className="workOnButton"> Work On! </Button>
                    </Grid>
                </Grid>
 
               {/* Filter OnClick PopUp */}
                <FilterPopup
                    open={openFilterPopup}
                    handleClose={handleCloseFilterPopup} filterButton
                    selectedCategory={selectedCategory}
                    selectedTechnology={selectedTechnology}
                    selectedComponentName={selectedComponentName}
                    handleCategoryChange={(event) =>
                        setSelectedCategory(event.target.value)
                    }
                    handleTechnologyChange={(event) =>
                        setSelectedTechnology(event.target.value)
                    }
                    handleComponentNameChange={(event) =>
                        setSelectedComponentName(event.target.value)
                    }
                    uniqueCategories={uniqueCategories}
                    uniqueTechnologies={uniqueTechnologies}
                    uniqueComponentNames={uniqueComponentNames}
                />
 
                {/* Add Component onClick PopUp */}
          <AddComponent open={ComponentPopup} handleClose={CloseComponentPopup} flagVal={currentTab}  />
        </div>
    );
}